/**
 * The link for outside of moneyfoward services.
 * These links are covered by daily availability check.
 */
export const publicLinks = {
  /** 「e-Tax の開始（変更等）届出書作成・提出コーナー」についてよくある質問 */
  etaxFaq: "https://www.e-tax.nta.go.jp/toiawase/qa/kaishi/01.htm",
  /** 税務署の所在地などを知りたい方｜国税庁 */
  ntaWhereTaxOffice: "https://www.nta.go.jp/about/organization/access/map.htm#map",
  /** No.6421 課税売上割合が著しく変動したときの調整｜国税庁 */
  ntaFaq6421: "https://www.nta.go.jp/taxes/shiraberu/taxanswer/shohi/6421.htm",
  /** No.6609 中間申告の方法｜国税庁 */
  ntaFaq6609: "https://www.nta.go.jp/taxes/shiraberu/taxanswer/shohi/6609.htm",
  /** 消費税法改正のお知らせ */
  ntaConsumptionTaxLawRevision: "https://www.nta.go.jp/publication/pamph/shohi/r02kaisei.pdf",
  /** 2割特例（インボイス発行事業者となる小規模事業者に対する負担軽減措置）の概要｜国税庁 */
  ntaSpecialException20percent:
    "https://www.nta.go.jp/publication/pamph/shohi/kaisei/202304/01.htm",
} as const;

/** a set of links to MoneyForward corporate site. */
export const corpSiteLinks = {
  /** 利用規約 */
  agreement: "https://biz.moneyforward.com/agreement",
  /** 個人情報保護方針 */
  privacy: "https://corp.moneyforward.com/privacy/",
  /** 外部送信ツールに関する公表事項 */
  privacyExternalTools: "https://corp.moneyforward.com/privacy/external-tools/",
} as const;
