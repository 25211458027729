import { css } from "@emotion/react";
import * as icons from "../icons";
import { ToastTheme } from "./types";

const styles = {
  container: css`
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    place-items: center;
    width: 440px;
    padding: 16px;
    font-size: 13px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px 2px rgb(0 0 0 / 15%);
  `,
  icon: css`
    display: grid;
    flex: 0 0 auto;
    place-items: center;
  `,
  text: css`
    flex: 1 1 auto;
    word-break: normal;
    white-space: pre-wrap;
  `,
  close: css`
    display: grid;
    flex: 0 0 auto;
    place-items: center;
    padding: 0; /* clear default button style */
    font-size: 24px;
    color: #333;
    text-align: left; /* clear default button style */
    cursor: pointer;
    background: none; /* clear default button style */
    border: none; /* clear default button style */
  `,
  iconSuccess: css`
    font-size: 24px;
    color: #3b7de9;
  `,
  iconError: css`
    font-size: 24px;
    color: #ec4949;
  `,
} as const;

const iconByTheme: { readonly [_ in ToastTheme]: React.ReactNode } = {
  success: <icons.Success css={styles.iconSuccess} />,
  error: <icons.Error css={styles.iconError} />,
};

export const Toast: React.FC<{
  className?: string | undefined;
  theme: ToastTheme;
  children: React.ReactNode;
  onClose: () => void;
}> = (props) => (
  <div css={styles.container} className={props.className} data-testid="toast">
    <span css={styles.icon}>{iconByTheme[props.theme]}</span>
    <span css={styles.text}>{props.children}</span>
    <button css={styles.close} onClick={props.onClose}>
      <icons.Close />
    </button>
  </div>
);
