import { css } from "@emotion/react";
import React from "react";
import {
  Dropdown,
  MenuContainer,
  MenuItem,
  MenuSeparator,
  MenuLink,
} from "../../../components/dropdown";
import * as icons from "../../../components/icons";
import { Link } from "../../../components/link";
import { TooltipArea } from "../../../components/tooltip";
import { getCaWebSupportsUrl, corpSiteLinks } from "../../../utils/external-url";
import { publicRuntimeConfig } from "../../../utils/next-helpers/runtime-config";
import { HeaderState } from "./types";

const styles = {
  container: css`
    display: flex;
    flex-flow: row nowrap;
    column-gap: 32px;
    align-items: center;
  `,
  helpMenu: css`
    width: 300px;
    font-size: 16px;
  `,
  helpMenuOfficeIdentificationCode: css`
    color: #999;
  `,
  helpMenuLabel: css`
    display: grid;
    grid-auto-flow: row;
    place-content: center;
  `,
  helpMenuLabelIcon: css`
    font-size: 24px;
    color: #7c8291;
    cursor: pointer;

    &:hover {
      color: #3b7de9;
    }
  `,
  supportPageLink: css`
    display: flex;
    flex-flow: row nowrap;
    column-gap: 4px;
    align-items: center;
    font-size: 13px;
    color: #333;

    --internal-icon-color: #7c8291;

    &:hover {
      color: #3b7de9;

      --internal-icon-color: #3b7de9;
    }
  `,
  supportPageLinkIcon: css`
    font-size: 24px;
    color: var(--internal-icon-color);
  `,
  viewerMenuLabel: css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
  `,
  viewMenuLabelText: css`
    max-width: 220px;
    overflow: hidden;

    /** change font-family for centerizing ellipsis mark */
    font-family: "メイリオ", sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  viewerMenuLabelIcon: css`
    font-size: 24px;
    color: #7c8291;
  `,
  viewerMenu: css`
    width: 296px;
    font-size: 13px;
  `,
  viewerMenuOfficeIdentificationCode: css`
    font-size: 12px;
    color: #666;
  `,
} as const;

const MANUAL_PAGE_URL = "https://support.biz.moneyforward.com/account/faq";
const INTRODUCING_TAX_ACCOUNTANT_PAGE_URL =
  "https://biz.moneyforward.com/mfc-partner/advisor-referrals/?provider=consumption-tax_login&provider_info=cv.home.part.header";

const formatOfficeIdentificationCode = (s: string) => s.replace(/^(\d{4})(\d{4})$/g, "$1-$2");

const HelpMenu: React.FC<{
  officeIdentificationCode: string;
}> = ({ officeIdentificationCode }) => {
  const renderContent = (): React.ReactNode => (
    <MenuContainer css={styles.helpMenu}>
      <MenuLink href={getCaWebSupportsUrl()} target="_blank" rel="noopener noreferrer">
        サポート
      </MenuLink>
      <MenuLink href={MANUAL_PAGE_URL} target="_blank" rel="noopener noreferrer">
        使い方・FAQ
      </MenuLink>
      <MenuLink href={INTRODUCING_TAX_ACCOUNTANT_PAGE_URL} target="_blank">
        税理士の無料紹介サービス
      </MenuLink>
      <MenuSeparator />
      <MenuLink href={corpSiteLinks.agreement} target="_blank" rel="noopener noreferrer">
        利用規約
      </MenuLink>
      <MenuLink href={corpSiteLinks.privacy} target="_blank" rel="noopener noreferrer">
        個人情報保護方針
      </MenuLink>
      <MenuLink href={corpSiteLinks.privacyExternalTools} target="_blank" rel="noopener noreferrer">
        外部送信ツールに関する公表事項
      </MenuLink>
      <MenuSeparator />
      <MenuItem css={styles.helpMenuOfficeIdentificationCode}>
        事業者番号：{formatOfficeIdentificationCode(officeIdentificationCode)}
      </MenuItem>
    </MenuContainer>
  );

  return (
    <Dropdown css={styles.helpMenuLabel} renderContent={renderContent}>
      {(ref) => (
        <div ref={ref} css={styles.helpMenuLabel}>
          <TooltipArea css={styles.helpMenuLabel} renderContent={() => "ヘルプ"}>
            <icons.Help css={styles.helpMenuLabelIcon} />
          </TooltipArea>
        </div>
      )}
    </Dropdown>
  );
};

const SupportPageLink: React.FC<{ href: string }> = (props) => (
  <Link
    href={props.href}
    css={styles.supportPageLink}
    data-testid="supportPageLink"
    target="_blank"
    rel="noopener noreferrer"
  >
    <icons.PlayCircle css={styles.supportPageLinkIcon} />
    このページのガイド
  </Link>
);

const ViewerMenu: React.FC<{
  officeName: string;
  officeIdentificationCode: string;
  onClickSignOut: () => void;
}> = (props) => {
  const renderContent = (): React.ReactNode => (
    <MenuContainer css={styles.viewerMenu} data-testid="viewerMenu">
      <MenuItem>
        <div data-testid="officeName">{props.officeName}</div>
        <div data-testid="officeIdentificationCode" css={styles.viewerMenuOfficeIdentificationCode}>
          事業者番号：{formatOfficeIdentificationCode(props.officeIdentificationCode)}
        </div>
      </MenuItem>
      <MenuLink href={publicRuntimeConfig.getString("NEXT_PUBLIC_SWITCH_TERM_URL") ?? undefined}>
        事業者・年度の管理
      </MenuLink>
      <MenuSeparator />
      <MenuLink onClick={props.onClickSignOut}>ログアウト</MenuLink>
    </MenuContainer>
  );

  return (
    <Dropdown renderContent={renderContent}>
      {(ref) => (
        <span
          data-testid="viewerMenuLabel"
          css={styles.viewerMenuLabel}
          ref={ref}
          title={props.officeName}
        >
          <span css={styles.viewMenuLabelText}>{props.officeName}</span>
          <icons.ArrowDropdown css={styles.viewerMenuLabelIcon} />
        </span>
      )}
    </Dropdown>
  );
};

export const MenuArea: React.FC<{
  headerState: HeaderState;
  supportPageUrl: string | null;
  onClickSignOut: () => void;
}> = (props) => {
  if (props.headerState.status !== "success") {
    return null;
  }
  const { viewer } = props.headerState;

  return (
    <div css={styles.container}>
      <HelpMenu officeIdentificationCode={viewer.officeIdentificationCode} />
      {props.supportPageUrl && <SupportPageLink href={props.supportPageUrl} />}
      <ViewerMenu
        officeName={viewer.officeName}
        officeIdentificationCode={viewer.officeIdentificationCode}
        onClickSignOut={props.onClickSignOut}
      />
    </div>
  );
};
