import { css } from "@emotion/react";
import { memo } from "react";
import { Transition, TransitionGroup, TransitionStatus } from "react-transition-group";
import { Z_INDEX } from "../../utils/styles";
import { Toast } from "./toast";
import { ToastItem } from "./types";

/** CSS transition duration */
const CSS_TRANSITION_DURATION = 300;
/** conditional class for CSS transition */
const CSS_IS_ACTIVE = "is_active";

const styles = {
  container: css`
    position: fixed;
    bottom: 80px;
    left: 32px;
    z-index: ${Z_INDEX.TOAST};
    display: flex;
    flex-flow: column-reverse nowrap;
    gap: 8px;
  `,
  listItem: css`
    transition: transform ${CSS_TRANSITION_DURATION}ms ease-out;
    transform: translateX(-500px);
    &.${CSS_IS_ACTIVE} {
      transform: none;
    }
  `,
} as const;

const classNameByTransitionStatus: { readonly [_ in TransitionStatus]?: string } = {
  entered: CSS_IS_ACTIVE,
  exited: CSS_IS_ACTIVE,
  exiting: CSS_IS_ACTIVE,
};

const nullRef: React.RefObject<undefined> = { current: null };

/**
 * A component that shows a column of Toast components.
 * @note This component should be rendered below the bottom of a page.
 */
export const ToastHolder: React.FC<{
  items: readonly ToastItem[];
  onClose: (id: string) => void;
}> = memo((props) => {
  return (
    <div css={styles.container}>
      <TransitionGroup component={null} exit={false}>
        {props.items.map((item) => (
          <Transition
            key={item.id}
            // prevent `<Transition/>` from using deprecated `ReactDOM.findDOMNode()`.
            nodeRef={nullRef}
            timeout={CSS_TRANSITION_DURATION}
          >
            {(status) => (
              <span css={styles.listItem} className={classNameByTransitionStatus[status]}>
                <Toast theme={item.theme} onClose={() => props.onClose(item.id)}>
                  {item.text}
                </Toast>
              </span>
            )}
          </Transition>
        ))}
      </TransitionGroup>
    </div>
  );
});
