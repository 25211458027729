import { css } from "@emotion/react";
import Image from "next/image";
import { OfficeType } from "../../../generated/graphql-types";
import logoCorporateImage from "./logo-corporate.svg";
import logoIndividualImage from "./logo-individual.svg";
import { HeaderState } from "./types";

const styles = {
  container: css`
    display: flex;
    flex-flow: row nowrap;
    column-gap: 8px;
    align-items: center;
  `,
  logoLabel: css`
    font-size: 14px;
  `,
  proxyLogin: css`
    font-size: 14px;
    font-weight: bold;
    color: red;
  `,
} as const;

export const LogoLabel: React.FC<{
  headerState: HeaderState;
}> = ({ headerState }) => {
  if (headerState.status === "pending") {
    return null;
  }
  const viewer = headerState.viewer;
  return (
    <div css={styles.container}>
      {viewer?.officeType === OfficeType.Individual ? (
        <Image src={logoIndividualImage} alt="logo" />
      ) : (
        <Image src={logoCorporateImage} alt="logo" />
      )}
      <span css={styles.logoLabel}>消費税申告</span>
      {viewer?.isProxy && <span css={styles.proxyLogin}>代行ログイン中</span>}
    </div>
  );
};
