import { useApolloClient } from "@apollo/client";
import React from "react";
import { useSideEffectViewActions } from "../../../contexts/side-effect-view";
import { removeAccessToken } from "../../../utils/cookie";
import { getCaWebTopUrl } from "../../../utils/external-url";
import { LogoutDocument } from "./graphql/logout-mutation";

export const useSignOut = (isProxy: boolean): (() => Promise<void>) => {
  const sideEffectActions = useSideEffectViewActions();
  const apolloClient = useApolloClient();

  const signOut = React.useCallback(async (): Promise<void> => {
    try {
      const { data } = await apolloClient.mutate({ mutation: LogoutDocument });
      removeAccessToken();
      // ログアウト後の戻り値なので null にはならない
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.assign(isProxy ? getCaWebTopUrl() : data!.logout.logoutURI);
    } catch (e: unknown) {
      sideEffectActions.handleActionError(e, "ログアウトに失敗しました。");
    }
  }, [sideEffectActions, apolloClient, isProxy]);

  return signOut;
};
