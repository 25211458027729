import { useQuery } from "@apollo/client";
import immer from "immer";
import { useRouter } from "next/router";
import { memo, useMemo } from "react";
import { useEffectOnce } from "react-use";
import { useEventHub } from "../../../contexts/event-hub";
import { useCurrentCaTerm } from "../../../hooks/use-current-ca-term";
import { getSupportPageUrl } from "../../../utils/external-url";
import { convertIntoViewer } from "./converter";
import { InitHeaderDocument } from "./graphql/init-header-query";
import { HeaderView } from "./header-view";
import { HeaderState } from "./types";
import { useSignOut } from "./use-sign-out";

const useHeaderState = (): HeaderState => {
  const currentCaTerm = useCurrentCaTerm();
  // FIXME: ヘッダの追加を行う apollo-client のラッパーを実装する
  const headers = useMemo<Readonly<Record<string, string>>>(() => {
    return immer<Record<string, string>>({}, (draft) => {
      if (currentCaTerm) {
        // バックエンド側で課税方式の判定を CA の会計期間で行うことを示す HTTP ヘッダ
        draft["x-ctax-use-current-ca-term"] = "true";
      }
    });
  }, [currentCaTerm]);
  const { data, error, refetch } = useQuery(InitHeaderDocument, { context: { headers } });
  const eventHub = useEventHub();
  useEffectOnce(() => eventHub.on("mutateCurrentTaxationPeriod", () => void refetch()));
  const headerState = useMemo<HeaderState>(() => {
    if (data) {
      return { status: "success", viewer: convertIntoViewer(data) };
    }
    if (error) {
      return { status: "error" };
    }
    return { status: "pending" };
  }, [data, error]);
  return headerState;
};

export const Header = memo(
  (props: {
    className?: string | undefined;
    /** if true, current taxation period isn't rendered */
    noTaxationPeriod?: boolean | undefined;
  }) => {
    const headerState = useHeaderState();
    const signOut = useSignOut(headerState.viewer?.isProxy ?? false);
    const router = useRouter();
    const supportPageUrl = useMemo((): string | null => {
      if (headerState.status === "success") {
        return getSupportPageUrl(router.pathname, headerState.viewer.officeType);
      }
      return null;
    }, [router, headerState]);

    return (
      <HeaderView
        className={props.className}
        headerState={headerState}
        supportPageUrl={supportPageUrl}
        onClickSignOut={signOut}
        omitTaxationPeriod={props.noTaxationPeriod}
      />
    );
  }
);
