/* eslint-disable */
// This file was automatically generated and should not be edited.
// @ts-nocheck
import * as Types from "../../../../generated/graphql-types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type LogoutVariables = Types.Exact<{ [key: string]: never }>;

export type Logout = {
  readonly logout: { readonly __typename: "LogoutPayload"; readonly logoutURI: string };
};

export const LogoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Logout" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "logout" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "logoutURI" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Logout, LogoutVariables>;
