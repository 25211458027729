import { css } from "@emotion/react";
import React from "react";
import { formatDate, formatTaxationPeriodStatus } from "../../../utils/format";
import { HeaderState } from "./types";

const styles = {
  container: css`
    display: flex;
    flex-flow: row nowrap;
    column-gap: 4px;
    align-items: center;
  `,
  periodLabel: css`
    font-size: 14px;
    color: #333;
  `,
  statusLabel: css`
    display: inline-grid;
    place-content: center;
    padding: 2px 4px;
    font-size: 12px;
    color: #fff;
    background: #7c8291;
    border: 1px solid #7c8291;
  `,
} as const;

export const TaxationPeriodLabel: React.FC<{ headerState: HeaderState }> = ({ headerState }) => {
  if (headerState.status !== "success") {
    return null;
  }
  const { currentTaxationPeriod } = headerState.viewer;
  if (currentTaxationPeriod == null) {
    return null;
  }

  return (
    <div css={styles.container}>
      <span data-testid="taxationPeriodLabel" css={styles.periodLabel}>
        課税期間 {formatDate(currentTaxationPeriod.startDate)}〜
        {formatDate(currentTaxationPeriod.endDate)}
      </span>
      <span data-testid="taxationPeriodStatus" css={styles.statusLabel}>
        {formatTaxationPeriodStatus(currentTaxationPeriod.status)}
      </span>
    </div>
  );
};
