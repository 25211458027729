import { css } from "@emotion/react";
import { Placement } from "@popperjs/core";
import { useState } from "react";
import { PopperGlue } from "./popper-glue";

const styles = {
  container: css`
    display: inline-block;
  `,
} as const;

export type TooltipAreaProps = {
  children?: React.ReactNode;
  className?: string;
  renderContent: () => React.ReactNode;
  placement?: Placement;
  "data-testid"?: string | undefined;
};

export const TooltipArea: React.FC<TooltipAreaProps> = (props) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  return (
    <>
      <div
        className={props.className}
        css={styles.container}
        ref={setReferenceElement}
        onMouseEnter={(): void => setHovered(true)}
        onMouseLeave={(): void => setHovered(false)}
        data-testid={props["data-testid"]}
      >
        {props.children}
      </div>
      {isHovered && (
        <PopperGlue referenceElement={referenceElement} placement={props.placement}>
          {props.renderContent()}
        </PopperGlue>
      )}
    </>
  );
};
