import { css } from "@emotion/react";
import React from "react";
import { usePopper, StrictModifier } from "react-popper";
import { useClickAway } from "react-use";

const styles = {
  popperGlue: css`
    z-index: 1;
  `,
} as const;

const popperModifiers: readonly StrictModifier[] = [
  // @see https://popper.js.org/docs/v2/modifiers/prevent-overflow/
  { name: "preventOverflow" },
];

const PopperGlue: React.FC<{
  children?: React.ReactNode;
  referenceElement: HTMLElement | null;
}> = (props) => {
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  const p = usePopper(props.referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: popperModifiers,
  });

  return (
    <div
      ref={setPopperElement}
      css={styles.popperGlue}
      style={p.styles.popper}
      {...p.attributes.popper}
    >
      {props.children}
    </div>
  );
};

export const Dropdown: React.FC<{
  className?: string;
  renderContent: () => React.ReactNode;
  children: (ref: (_: HTMLElement | null) => void) => React.ReactNode;
}> = (props) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);

  // toggle on click reference element
  React.useEffect(() => {
    const callback = (e: Event) => {
      // prevent double click selection
      e.preventDefault();
      setOpen((isOpen) => !isOpen);
    };
    referenceElement?.addEventListener("mousedown", callback);
    referenceElement?.addEventListener("touchstart", callback);
    return () => {
      referenceElement?.removeEventListener("mousedown", callback);
      referenceElement?.removeEventListener("touchstart", callback);
    };
  }, [referenceElement]);

  useClickAway(ref, () => setOpen(false));

  return (
    <>
      <div className={props.className} ref={ref}>
        {props.children(setReferenceElement)}
        {isOpen && (
          <PopperGlue referenceElement={referenceElement}>{props.renderContent()}</PopperGlue>
        )}
      </div>
    </>
  );
};
