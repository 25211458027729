import { OfficeType } from "../../generated/graphql-types";

const BASE_URL = "https://biz.moneyforward.com/support";

/**
 * 現在の pathname から法人のサポートページの URL を引ける連想配列
 * @note ルーティングに依存した定数なのでルート変更する場合はこちらも修正してください
 */
const corporateSupportPageUrlByPathname: Readonly<Record<string, string>> = {
  "/taxation-periods": `${BASE_URL}/account/guide/consumption-tax/ct01.html`,
  "/basic-information": `${BASE_URL}/account/guide/consumption-tax/ct02.html`,
  "/declaration-information": `${BASE_URL}/account/guide/consumption-tax/ct03.html`,
  "/declaration-basic-data": `${BASE_URL}/account/guide/consumption-tax/ct04.html`,
  "/preview": `${BASE_URL}/account/guide/consumption-tax/ct05.html`,
  "/refund-declaration-details": `${BASE_URL}/account/guide/consumption-tax/ct06.html`,
  "/interim-payment-information": `${BASE_URL}/account/guide/consumption-tax/ct07.html`,
};

/**
 * 現在の pathname から個人のサポートページの URL を引ける連想配列
 * @note ルーティングに依存した定数なのでルート変更する場合はこちらも修正してください
 */
const individualSupportPageUrlByPathname: Readonly<Record<string, string>> = {
  "/taxation-periods": `${BASE_URL}/tax-return/guide/consumption-tax/ct01.html`,
  "/basic-information": `${BASE_URL}/tax-return/guide/consumption-tax/ct02.html`,
  "/declaration-information": `${BASE_URL}/tax-return/guide/consumption-tax/ct03.html`,
  "/declaration-basic-data": `${BASE_URL}/tax-return/guide/consumption-tax/ct04.html`,
  "/preview": `${BASE_URL}/tax-return/guide/consumption-tax/ct05.html`,
  "/refund-declaration-details": `${BASE_URL}/tax-return/guide/consumption-tax/ct06.html`,
  "/interim-payment-information": `${BASE_URL}/tax-return/guide/consumption-tax/ct07.html`,
};

/**
 * サポートページの URL を返します
 * @param pathname 現在のページの pathname
 * @param officeType 事業者の種類
 */
export const getSupportPageUrl = (pathname: string, officeType: OfficeType): string | null => {
  switch (officeType) {
    case OfficeType.Individual:
      return individualSupportPageUrlByPathname[pathname] ?? null;
    case OfficeType.Corporate:
      return corporateSupportPageUrlByPathname[pathname] ?? null;
  }
};
