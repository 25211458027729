import { Fragment, memo, useCallback, useEffect } from "react";
import { ToastHolder } from "../../components/toast";
import { useSideEffectViewStore, useSideEffectViewActions } from "./context";

const ConnectedModal: React.FC = memo(() => {
  const { clearModalStack } = useSideEffectViewActions();
  useEffect(() => {
    return () => {
      // because modals shouldn't be carried over through page transition,
      // here it clears modal stack when the page is being unloaded.
      clearModalStack();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const modals = useSideEffectViewStore(useCallback((state) => state.modals, []));
  return (
    <>
      {modals.map((modal) => (
        <Fragment key={modal.id}>{modal.node}</Fragment>
      ))}
    </>
  );
});

const ConnectedToastHolder: React.FC = memo(() => {
  const toastItems = useSideEffectViewStore(useCallback((state) => state.toastItems, []));
  const actions = useSideEffectViewActions();
  return <ToastHolder items={toastItems} onClose={actions.removeToast} />;
});

export const ConnectedSideEffectView: React.FC = () => (
  <>
    <ConnectedModal />
    <ConnectedToastHolder />
  </>
);
