import { css } from "@emotion/react";
import React from "react";
import { Anchor, AnchorProps } from "../link";

const styles = {
  container: css`
    min-width: 184px;
    padding: 8px 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 1px 2px 4px rgb(0 0 0 / 30%);
  `,
  menuItem: css`
    padding: 8px 16px;
  `,
  menuLink: css`
    display: block;
    padding: 8px 16px;
    color: #333; /* overwrite user-agent style */
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #3b7de9;
    }
  `,
  separator: css`
    padding: 8px 0;
  `,
  separatorLine: css`
    border-top: solid 1px #d4d8dd;
  `,
} as const;

export const MenuContainer: React.FC<React.ComponentPropsWithoutRef<"div">> = ({
  children,
  ...others
}) => (
  <div {...others} css={styles.container}>
    {children}
  </div>
);

export const MenuItem: React.FC<React.ComponentPropsWithoutRef<"div">> = ({
  children,
  ...others
}) => {
  return (
    <div {...others} css={styles.menuItem}>
      {children}
    </div>
  );
};

export const MenuLink: React.FC<Omit<AnchorProps, "ref">> = ({ children, ...others }) => {
  return (
    <Anchor {...others} css={styles.menuLink}>
      {children}
    </Anchor>
  );
};

export const MenuSeparator: React.FC = () => (
  <div css={styles.separator}>
    <div css={styles.separatorLine} />
  </div>
);
