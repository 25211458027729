import Head from "next/head";
import { memo } from "react";

const BASE_TITLE = "マネーフォワード クラウド消費税申告";

export const HeadTitle: React.FC<{ title?: string | null }> = memo((props) => {
  const title = props.title == null ? BASE_TITLE : `${props.title} | ${BASE_TITLE}`;
  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
});
