import { css } from "@emotion/react";
import React from "react";
import { Spacer } from "../../../components/spacer";
import { PC_SCREEN_WIDTH, LAYOUT_HEADER_HEIGHT } from "../../../utils/styles";
import { LogoLabel } from "./logo-label";
import { MenuArea } from "./menu-area";
import { TaxationPeriodLabel } from "./taxation-period-label";
import { HeaderState } from "./types";

const styles = {
  container: css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: ${PC_SCREEN_WIDTH};
    height: ${LAYOUT_HEADER_HEIGHT};
    padding: 12px;
    font-size: 13px;
    background: #fff;
    box-shadow:
      0 4px 5px rgb(0 0 0 / 14%),
      0 1px 10px rgb(0 0 0 / 12%),
      0 2px 4px rgb(0 0 0 / 20%);
  `,
  spacer: css`
    flex: 1 1 auto;
  `,
} as const;

export const HeaderView: React.FC<{
  className?: string | undefined;
  headerState: HeaderState;
  supportPageUrl: string | null;
  onClickSignOut: () => void;
  /** if true, current taxation period isn't rendered */
  omitTaxationPeriod?: boolean | undefined;
}> = (props) => {
  return (
    <header css={styles.container} className={props.className}>
      <LogoLabel headerState={props.headerState} />
      <Spacer hr size={32} />
      {props.omitTaxationPeriod !== true && <TaxationPeriodLabel headerState={props.headerState} />}
      <div css={styles.spacer} />
      <MenuArea
        headerState={props.headerState}
        supportPageUrl={props.supportPageUrl}
        onClickSignOut={props.onClickSignOut}
      />
    </header>
  );
};
