import { css, SerializedStyles } from "@emotion/react";
import { forwardRef, useMemo } from "react";
import { Information as IconInformation } from "../icons";

const styles = {
  ul: css`
    justify-content: start;
    padding: 0;
    margin: 0;
    list-style-type: none;

    & > li {
      padding: 0;
      margin: 0;
      margin-left: 1em;

      &::before {
        position: absolute;
        margin-left: -1em;
        content: "・";
      }
    }
  `,
  inlineInformation: css`
    display: flex;
    gap: 4px;
    align-items: flex-start;
  `,
  inlineInformationIcon: css`
    flex: 0 0 auto;
    color: #3b7de9;
  `,
  inlineInformationText: css`
    flex: 1 1 auto;
  `,
} as const;

export type BodySize = "body" | "caption";
/** base styles for "body" and "caption" */
export const baseStyles: Readonly<Record<BodySize, SerializedStyles>> = {
  body: css`
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
  `,
  caption: css`
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  `,
};

type BodyColor = "inherit" | "black" | "gray";
const colorStyles: Readonly<Record<BodyColor, SerializedStyles>> = {
  inherit: css`
    color: inherit;
  `,
  black: css`
    color: #333;
  `,
  gray: css`
    color: #666;
  `,
};

/**
 * @param size font-size
 * @param color color
 * @returns combined emotion style object
 */
const createBodyStyle = (size: BodySize = "body", color: BodyColor = "inherit"): SerializedStyles =>
  css(baseStyles[size], colorStyles[color]);

/** body/caption block text (paragraph) (`<p/>` alternative) */
export const P: React.FC<
  React.ComponentPropsWithRef<"p"> & {
    size?: BodySize | undefined;
    color?: BodyColor | undefined;
  }
> = forwardRef(({ size, color, children, ...others }, ref) => {
  const combinedStyle = useMemo(() => createBodyStyle(size, color), [size, color]);
  return (
    <p {...others} css={combinedStyle} ref={ref}>
      {children}
    </p>
  );
});

/** body/caption inline text (`<span/>` alternative) */
export const Text: React.FC<
  React.ComponentPropsWithRef<"span"> & {
    size?: BodySize | undefined;
    color?: BodyColor | undefined;
  }
> = forwardRef(({ size, color, children, ...others }, ref) => {
  const combinedStyle = useMemo(() => createBodyStyle(size, color), [size, color]);
  return (
    <span {...others} css={combinedStyle} ref={ref}>
      {children}
    </span>
  );
});

/**
 * Unordered list component (`<ul/>` alternative)
 * @note please use `<li/>` for `props.children`
 */
export const Ul: React.FC<
  Omit<React.ComponentProps<"ul">, "children"> & {
    size?: BodySize | undefined;
    color?: BodyColor | undefined;
    children: React.ReactNode;
  }
> = ({ children, size, color, ...others }) => {
  const combinedStyle = useMemo(() => css(styles.ul, createBodyStyle(size, color)), [size, color]);
  return (
    <ul {...others} css={combinedStyle}>
      {children}
    </ul>
  );
};

const inlineInformationIconSizeStyle: Readonly<Record<BodySize, SerializedStyles>> = {
  body: css`
    margin-top: 2px; /* adjust vertical position */
    font-size: 20px;
  `,
  caption: css`
    font-size: 18px;
  `,
};

/** inline information text */
export const InlineInformation: React.FC<{
  size?: BodySize | undefined;
  children?: React.ReactNode;
  className?: string | undefined;
  "data-testid"?: string | undefined;
}> = ({ size = "body", ...props }) => {
  const iconStyle = useMemo((): SerializedStyles => {
    return css(styles.inlineInformationIcon, inlineInformationIconSizeStyle[size]);
  }, [size]);
  return (
    <div
      css={styles.inlineInformation}
      className={props.className}
      data-testid={props["data-testid"]}
    >
      <IconInformation css={iconStyle} />
      <Text size={size} css={styles.inlineInformationText}>
        {props.children}
      </Text>
    </div>
  );
};
