import { css } from "@emotion/react";
import { Placement } from "@popperjs/core";
import { useState, useMemo } from "react";
import { usePopper, StrictModifier } from "react-popper";

const styles = {
  arrow: css`
    z-index: -1;
    visibility: hidden;

    &,
    &::before {
      position: absolute;
      width: 8px;
      height: 8px;
    }

    &::before {
      visibility: visible;
      content: "";
      background: #333;
      transform: rotate(45deg);
    }

    [data-popper-placement^="top"] > & {
      bottom: -4px;
    }

    [data-popper-placement^="bottom"] > & {
      top: -4px;
    }

    [data-popper-placement^="left"] > & {
      right: -4px;
    }

    [data-popper-placement^="right"] > & {
      left: -4px;
    }
  `,
  tooltip: css`
    z-index: 1;
    padding: 4px 8px;
    font-size: 12px;
    color: #fff;
    background: #333;
    border-radius: 4px;
  `,
} as const;

export const PopperGlue: React.FC<{
  children?: React.ReactNode;
  referenceElement: HTMLElement | null | undefined;
  placement?: Placement | null | undefined;
}> = (props) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const modifiers = useMemo<readonly StrictModifier[]>(
    () => [
      /** @see https://popper.js.org/docs/v2/modifiers/prevent-overflow/ */
      { name: "preventOverflow" },
      /** @see https://popper.js.org/docs/v2/modifiers/arrow/ */
      { name: "arrow", options: { element: arrowElement } },
      /** @see https://popper.js.org/docs/v2/modifiers/offset/ */
      { name: "offset", options: { offset: [0, 8] } },
    ],
    [arrowElement]
  );
  const p = usePopper(props.referenceElement, popperElement, {
    placement: props.placement ?? "top",
    modifiers,
  });

  return (
    <div {...p.attributes.popper} ref={setPopperElement} style={p.styles.popper}>
      <div ref={setArrowElement} css={styles.arrow} style={p.styles.arrow} />
      <div css={styles.tooltip}>{props.children}</div>
    </div>
  );
};
