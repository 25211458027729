import { InitHeader } from "./graphql/init-header-query";
import { TaxationPeriod, Viewer } from "./types";

export const convertIntoViewer = (data: InitHeader): Viewer => {
  const { viewer } = data;

  let currentTaxationPeriod: TaxationPeriod | null = null;
  if (
    viewer.currentTaxationPeriod != null &&
    // officeID が異なる場合は表示しない
    viewer.currentTaxationPeriod.officeID === viewer.basicSetting.officeID
  ) {
    currentTaxationPeriod = viewer.currentTaxationPeriod;
  }

  return { ...viewer.basicSetting, currentTaxationPeriod, isProxy: viewer.isProxy };
};
