/* eslint-disable */
// This file was automatically generated and should not be edited.
// @ts-nocheck
import * as Types from "../../../../generated/graphql-types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type InitHeaderVariables = Types.Exact<{ [key: string]: never }>;

export type InitHeader = {
  readonly viewer: {
    readonly __typename: "User";
    readonly isProxy: boolean;
    readonly basicSetting: {
      readonly __typename: "BasicSetting";
      readonly officeID: string;
      readonly officeName: string;
      readonly officeIdentificationCode: string;
      readonly officeType: Types.OfficeType;
    };
    readonly currentTaxationPeriod: {
      readonly __typename: "TaxationPeriod";
      readonly officeID: string;
      readonly startDate: string;
      readonly endDate: string;
      readonly status: Types.TaxationPeriodStatus;
    } | null;
  };
};

export const InitHeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "InitHeader" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isProxy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "basicSetting" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "officeID" } },
                      { kind: "Field", name: { kind: "Name", value: "officeName" } },
                      { kind: "Field", name: { kind: "Name", value: "officeIdentificationCode" } },
                      { kind: "Field", name: { kind: "Name", value: "officeType" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentTaxationPeriod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "officeID" } },
                      { kind: "Field", name: { kind: "Name", value: "startDate" } },
                      { kind: "Field", name: { kind: "Name", value: "endDate" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitHeader, InitHeaderVariables>;
