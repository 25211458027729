import { css } from "@emotion/react";
import { ConnectedSideEffectView } from "../../../contexts/side-effect-view";
import { PC_SCREEN_WIDTH, Z_INDEX } from "../../../utils/styles";
import { Header } from "../header";
import { HeadTitle } from "./head-title";

const styles = {
  container: css`
    min-width: ${PC_SCREEN_WIDTH};
    min-height: 100vh;
    background: #fff;
  `,
  header: css`
    position: sticky;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.HEADER};
    width: 100%;
  `,
  body: css`
    display: grid;
    justify-content: center;
    text-align: center;
  `,
} as const;

export const ErrorPageLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <>
      <HeadTitle />
      <div css={styles.container}>
        <Header css={styles.header} />
        <div css={styles.body}>{props.children}</div>
      </div>
      <ConnectedSideEffectView />
    </>
  );
};
